<template>
  <div>
    <currentLocation :sibItem="sibItem"></currentLocation>
    <div class="outer">
      <img
        src="@/assets/img/checklanzuo.png"
        alt=""
        class="jiantou"
        @click="arrowClick(0)"
      />
      <el-carousel
        arrow="never"
        indicator-position="none"
        :autoplay="false"
        class="learning"
        ref="cardShow"
        @change="carouselChange"
      >
        <el-carousel-item
          v-for="(item, index) in learning"
          :key="index"
          class="carouItem"
        >
          <div
            v-for="(item2, index2) in item"
            :key="index2"
            class="itemInfo"
            @click="learningChange(index, index2)"
          >
            <img :src="item2.image" alt="" />
            <div
              :class="learningIndex == index2 ? 'title titleActive' : 'title'"
            >
              {{ item2.title }}
            </div>
            <div class="xian" v-if="learningIndex == index2"></div>
          </div>
        </el-carousel-item>
      </el-carousel>
      <img
        src="@/assets/img/checklan.png"
        alt=""
        class="jiantou"
        @click="arrowClick(1)"
      />
    </div>
    <div class="outerTwo">
      <div v-html="learning[learningOneIndex][learningIndex].content"></div>
    </div>
  </div>
</template>
<script>
/**
 * Created by www.xingnuocn.com
 * @title：行业服务-学术服务
 *
 * @author: 刘嘉鑫
 * @version  V1.0
 * @datecreate: 2022-08-16 17:12
 */
import currentLocation from "@/components/currentLocation.vue";
export default {
  components: {
    currentLocation,
  },
  data() {
    return {
      sibItem: [
        {
          title_zh: "行业服务",
          title_en: "Industry service",
          path: "industryService",
        },
        {
          title_zh: "学术活动",
          title_en: "academic activities",
          path: "",
        },
      ],
      learning: "",
      learningOneIndex: 0,
      learningIndex: 0,
    };
  },
  mounted() {
    this.geLearning();
  },
  methods: {
    /**
     * 详情
     * 刘嘉鑫
     * 2022-8-17
     */
    geLearning() {
      this.$request({
        url: "/Index/learning",
        data: {},
      }).then((res) => {
        console.log("学术活动", res);
        let result = []; //定义一个空数组
        for (var i = 0; i < res.learning_list.length; i += 4) {
          result.push(res.learning_list.slice(i, i + 4));
          res.learning_list[i].content = res.learning_list[i].content.replace(
            new RegExp("<img", "g"),
            "<img style='max-width:100%;height:auto;margin:0 auto;'"
          );
        }
        this.learning = result;
        console.log(result);
      });
    },

    /**
     * 轮播图切换
     * 刘嘉鑫
     * 2022-8-17
     */
    carouselChange(e) {
      this.learningOneIndex = e;
    },

    /**
     * 切换
     * 刘嘉鑫
     * 2022-8-17
     */
    learningChange(index, index2) {
      console.log(this.learning.length);
      this.learningOneIndex = index;
      this.learningIndex = index2;
      this.learning[index][index2].content = this.learning[index][
        index2
      ].content.replace(
        new RegExp("<img", "g"),
        "<img style='max-width:100%;height:auto;margin:0 auto;'"
      );
    },

    /**
     * 左右切换
     * 刘嘉鑫
     * 2022-8-17
     */
    arrowClick(val) {
      if (val == "1") {
        this.$refs.cardShow.prev();
        this.learningIndex = 0;
      } else {
        this.$refs.cardShow.next();
        this.learningIndex = 0;
      }
    },
  },
};
</script>
<style>
.learning .el-carousel__container {
  height: 274px !important;
}
</style>
<style lang='scss' scoped>
.outer {
  background: #ffffff;
  padding: 0 140px;
  display: flex;
  align-items: center;
  justify-content: space-between;

  .jiantou {
    width: 20px;
    height: 14px;
  }

  .learning {
    margin: 0 100px;
    width: 85%;
    .carouItem {
      display: flex;
      // justify-content: space-between;
      align-items: flex-start;
    }
    .itemInfo {
      padding: 41px 0 0;
      margin-right: 120px;
      width: 260px;
      display: flex;
      flex-direction: column;
      > img {
        width: 100%;
        height: 150px;
        background: #393939;
        margin-bottom: 15px;
      }

      .title {
        font-size: 16px;
        font-family: Source Han Sans CN;
        font-weight: bold;
        color: #444444;
        display: -webkit-box;
        overflow: hidden;
        text-overflow: ellipsis;
        word-wrap: break-word;
        word-break: break-all;
        white-space: normal !important;
        -webkit-line-clamp: 2;
        -webkit-box-orient: vertical;
        margin-bottom: 12px;
        padding: 0 4px;
        height: 45px;
      }

      .titleActive {
        color: #1a2a60 !important;
        margin-bottom: 18px !important;
      }

      .xian {
        width: 100%;
        height: 4px;
        background: #1a2a60;
      }
    }

    .itemInfo:nth-child(4n) {
      margin-right: 0 !important;
    }
  }
}

.outerTwo {
  background: #f6f6f6;
  padding: 80px 310px 120px;
}
</style>